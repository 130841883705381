//React
import React from 'react'
import { connect } from 'react-redux'

//Actions
import { updateCampaign } from '../actions/updateCampaign'

//Packages
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import parse from 'url-parse'

//Components
import NavMain from './nav/navMain'
import NavMinimal from './nav/navMinimal'
import NavNone from './nav/navNone'
import NavSubUsecase from './nav/navSubUsecases'
import Footer from './nav/footer'
import FooterMinimal from './nav/footerMinimal'
import PrefooterCta from './nav/PrefooterCta'

//Assets
import favicon from '../images/storylava-icon.png'
import './../scss/main.scss'

//Redux
const mapDispatchToProps = (dispatch) => ({
  updateCampaign: (campaignId) => {
    dispatch(updateCampaign(campaignId))
  },
})

class IndexPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      pageContext: '',
    }
  }

  static propTypes = {
    children: PropTypes.node.isRequired,
    location: PropTypes.node.isRequired,
  }

  componentDidMount() {
    const url = parse(window.location.href, true)

    console.log('window.location')
    console.log(window.location)
    console.log('((((url))))')
    console.log(url)
    if (url.query && url.query.c) {
      this.props.updateCampaign(url.query.c)
    }
    if (url.pathname) {
      const split = url.pathname.split('/')
      console.log('((((split))))')
      console.log(split)
      if (url.query && url.query.p && url.query.p === 'app') {
        this.setState({ pageContext: 'force-none' })
      } else if (split[1] === 'about' && split[2] === 'terms') {
        this.setState({ pageContext: 'legal' })
      } else if (split[1] === 'about' && split[2] === 'privacy') {
        this.setState({ pageContext: 'legal' })
      } else if (split[1] === 'about' && split[2] === 'challenge') {
        this.setState({ pageContext: split[2] })
      } else if (split[1] === 'about' && split[2] === 'rules') {
        this.setState({ pageContext: 'rules' })
      } else if (split[2] === 'onboarding' && split[3] === 'email') {
        this.setState({ pageContext: 'onboarding-email' })
      } else if (split[1] === 'app' && (!split[2] || split[2] === '')) {
        this.setState({ pageContext: 'app-login' })
      } else if (split[1] === 'app' && split[2] === 'password') {
        this.setState({ pageContext: 'app-password' })
      } else {
        this.setState({ pageContext: split[1] })
      }
    } else if (url.pathname === '/') {
      this.setState({
        pageContext: 'home',
      })
    }
  }

  render() {
    const { children } = this.props

    return (
      <div class="min-vh-100 d-flex flex-column">
        <Helmet
          link={[
            { rel: 'shortcut icon', type: 'image/png', href: `${favicon}` },
          ]}
        />

        {(() => {
          switch (this.state.pageContext) {
            case 'force-none':
              return <NavNone />
            case 'landing':
            case 'legal':
            case 'unsubscribe':
            case 'request-demo':
            case 'request-access':
              return <NavMinimal />
            case 'home':
              return <NavMain />
            default:
              return <NavMain />
          }
        })()}
        {children}

        {(() => {
          switch (this.state.pageContext) {
            case 'force-none':
            case 'landing':
            case 'legal':
            case 'unsubscribe':
              return <FooterMinimal />
            case 'request-demo':
            case 'request-access':
              return (
                <>
                  <FooterMinimal />
                </>
              )
            case 'home':
              return (
                <>
                  {/* <PrefooterCta /> */}
                  <FooterMinimal />
                  {/* <DriftBot /> */}
                </>
              )
            default:
              return (
                <>
                  {/* <PrefooterCta /> */}
                  <FooterMinimal />
                </>
              )
          }
        })()}
      </div>
    )
  }
}

export default connect(null, mapDispatchToProps)(IndexPage)
